import { graphql, navigate } from 'gatsby';
import { ENABLE_AMPLIFY_CHATBOT } from 'gatsby-env-variables';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ForgotNav from 'components/forgot-nav/forgot-nav';
import ForgotUsernameForm, {
    ForgotUsernameFailureModalContent,
    ForgotUsernameFailureModalHeader,
    ForgotUsernameSuccessModalContent
} from 'components/forgot-username-form/forgot-username-form.component';
import { ForgotUsernameFormValues } from 'components/forgot-username-form/forgot-username-form.props';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// State
import { accountForgotUsernameRoutine } from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

// Hooks
import { useGlobalLink } from 'hooks/useGlobalLink';

import './forgot-password.styles.scss';

const ForgotUsername = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.ForgotUsernameDataQuery;
}): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const globalLink = useGlobalLink();
    const { handleSignOut } = globalLink;

    const { isSubmittingUsernameRequest } = useSelector(accountStateSelector);

    const handleSubmitForgotUsernameForm = (values: ForgotUsernameFormValues) => {
        dispatch(
            accountForgotUsernameRoutine.trigger({
                email: values.email,
                onSuccess: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            onClose: () => navigate('/sign-in'),
                            bodyContent: <ForgotUsernameSuccessModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.forgotUsernameSuccess.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                        navigate('/sign-in');
                                    },
                                    dataGALocation: 'ForgotUsernameSuccess'
                                }
                            ]
                        })
                    );
                },
                onFailure: () => {
                    dispatch(
                        openModal({
                            showClose: true,
                            size: 'lg',
                            type: 'danger',
                            headerContent: <ForgotUsernameFailureModalHeader translation={t} />,
                            onClose: () => {
                                dispatch(closeModal({}));
                            },
                            bodyContent: <ForgotUsernameFailureModalContent translation={t} />,
                            ctas: [
                                {
                                    label: t('modals.forgotUsernameFailure.submit'),
                                    variant: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal({}));
                                    },
                                    dataGALocation: 'ForgotUsernameError'
                                }
                            ]
                        })
                    );
                }
            })
        );
    };

    useEffect(() => {
        return () => {
            if (!ENABLE_AMPLIFY_CHATBOT) {
                handleSignOut();
            }
        };
    }, [dispatch]);

    return (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('forgotUsername.labels.title') }}
        >
            <WorkflowLayoutFormWrapper
                currentFlow={'login'}
                eyebrowText={t('forgotUsername.labels.eyebrowText')}
                title={t('forgotUsername.labels.title')}
                nav={<ForgotNav />}
            >
                <ForgotUsernameForm
                    onSubmit={handleSubmitForgotUsernameForm}
                    isSubmittingUsernameRequest={isSubmittingUsernameRequest}
                />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default ForgotUsername;

export const query = graphql`
    query ForgotUsernameData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
